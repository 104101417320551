
      Office.onReady().then(() => {
        switch (Office.context.host) {
          case Office.HostType.Excel: {
            window.location.replace("/excel/#launch=" + launch);
            break;
          }
          case Office.HostType.Word: {
            window.location.replace("/word/#launch=" + launch);
            break;
          }
        }
      });
    